import {
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
} from '@chakra-ui/react';

import BlackCheck from '../../Assets/Images/check-black-fill.svg';

import CruwiCreditsButtonShape from '../CruwiCreditsButton/CruwiCreditsButtonShape';

function CruwiCreditsModal({ creditsNumber, isOpenCruwiCreditsModal, onCloseCruwiCreditsModal }) {
  return (
    <Modal
      isOpen={isOpenCruwiCreditsModal}
      onClose={onCloseCruwiCreditsModal}
      closeOnOverlayClick
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent border="2px solid black" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={4}>
          <Text fontSize="18px" mb={3} fontWeight="bold">
            Tus créditos
          </Text>
          <Flex mb={3}>
            <CruwiCreditsButtonShape creditsNumber={creditsNumber} />
          </Flex>
          <Box>
            <Text mb={3} fontSize="14px" fontWeight="semibold">
              ¿Qué es un crédito?
            </Text>
            <Box>
              <Flex mb="12px">
                <Box
                  cursor="pointer"
                  border="1px solid"
                  boxShadow="1px 1px 0px 0px #000"
                  mr={2}
                  width="20px"
                  height="20px"
                  minW="20px"
                  borderRadius="50%"
                  backgroundRepeat="no-repeat"
                  backgroundSize="10px"
                  backgroundPosition="center"
                  backgroundColor="#EBD0FF"
                  backgroundImage={`url(${BlackCheck})`}
                />
                <Text fontSize="14px" lineHeight={1.3}>
                  Utiliza un crédito cada vez que participes en un reto o una misión.
                </Text>
              </Flex>
              <Flex mb="20px">
                <Box
                  cursor="pointer"
                  border="1px solid"
                  boxShadow="1px 1px 0px 0px #000"
                  mr={2}
                  width="20px"
                  height="20px"
                  minW="20px"
                  borderRadius="50%"
                  backgroundRepeat="no-repeat"
                  backgroundSize="10px"
                  backgroundPosition="center"
                  backgroundColor="#EBD0FF"
                  backgroundImage={`url(${BlackCheck})`}
                />
                <Text fontSize="14px" lineHeight={1.3}>
                  Recupera el crédito cuando subas el vídeo del reto o misión.
                </Text>
              </Flex>
            </Box>
            <Box>
              <Text fontSize="14px" lineHeight={1.2}>
                Todo el mundo empieza con 2 créditos. Sube los vídeos de las campañas donde
                participes para no perderlos.
              </Text>
            </Box>
            <Button
              onClick={onCloseCruwiCreditsModal}
              mt={8}
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
            >
              Entendido
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CruwiCreditsModal;
