import { Box, useDisclosure } from '@chakra-ui/react';

import HelpModalChallenges from '../HelpModalChallenges/HelpModalChallenges';

import InfoIcon from '../../Assets/Images/pepicons-pop_question.svg';

export default function HelpButton() {
  const {
    isOpen: isOpenHelpModalUGC,
    onOpen: onOpenHelpModalUGC,
    onClose: onCloseHelpModalUGC,
  } = useDisclosure();
  return (
    <Box
      border="1.5px solid black"
      width="20px"
      height="20px"
      borderRadius="50%"
      backgroundRepeat="no-repeat"
      backgroundSize="12px"
      backgroundPosition="center"
      backgroundImage={`url(${InfoIcon})`}
      mr="7px"
      mt="2px"
      cursor="pointer"
      _hover={{
        backgroundColor: '#EBD0FF',
      }}
      onClick={onOpenHelpModalUGC}
    >
      <HelpModalChallenges isOpen={isOpenHelpModalUGC} onClose={onCloseHelpModalUGC} />
    </Box>
  );
}
