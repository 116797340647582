/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Box,
  useMediaQuery,
  Image,
  Button,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';
import { FaTiktok } from 'react-icons/fa';

import { useLazySendToTikTokAuthQuery } from '../../Redux/features/auth/authApiSlice';

import FollowersIcon from '../../Assets/Images/followers-icon.svg';
import VideosIcon from '../../Assets/Images/videos_icon.svg';

import './ConnectTikTokModal.css';

export default function ConnectTikTokModal({
  connectionError,
  isOpenConnectTikTokModal,
  onCloseConnectTikTokModal,
  handleClickCloseConnectTikTokModalAndClearParams,
}) {
  const [sendToTikTok] = useLazySendToTikTokAuthQuery();

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const handleClickConnectTikTok = async () => {
    try {
      const tikTokApiResult = await sendToTikTok();
      if (tikTokApiResult.isSuccess) {
        window.location.replace(tikTokApiResult.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const InfoContent = (
    <Box p={5}>
      <Text mb={2} fontSize={24} fontFamily="DM Sans" fontWeight="bold">
        Antes de continuar..
      </Text>
      <Text fontFamily="DM Sans" mb={4}>
        En CRUWI tenemos 2 requisitos que nos permiten conocer que eres o quieres ser creador/a de
        contenido.
      </Text>
      <Box
        fontFamily="DM Sans"
        bg="#fff"
        width="100%"
        borderStyle="dashed"
        borderColor="#000000"
        borderWidth="1px"
        borderRadius={6}
        mb={3}
        display="flex"
        alignItems="center"
        p={2}
      >
        <Box>
          <Image
            boxSize="40px"
            objectFit="cover"
            src={FollowersIcon}
            alt="min followers criteria"
          />
        </Box>
        <Box ml={2}>
          <Text fontWeight="semibold" fontFamily="DM Sans">
            Tener +500 seguidores
          </Text>
        </Box>
      </Box>
      <Box
        fontFamily="DM Sans"
        bg="#fff"
        width="100%"
        borderStyle="dashed"
        borderColor="#000000"
        borderWidth="1px"
        borderRadius={6}
        mb={4}
        display="flex"
        alignItems="center"
        p={2}
      >
        <Box>
          <Image boxSize="40px" objectFit="cover" src={VideosIcon} alt="min videos criteria" />
        </Box>
        <Box ml={2}>
          <Text fontWeight="semibold" fontFamily="DM Sans">
            Tener +10 vídeos
          </Text>
        </Box>
      </Box>
      <Text fontFamily="DM Sans" mb={4}>
        Si cumples ambos, podrás conectar tu TikTok y empezar a participar en los retos. Te
        recordamos que cuando completes el primero, ganarás 1€ extra :)
      </Text>
      <Box marginTop="50px">
        <Button
          onClick={() => handleClickConnectTikTok()}
          width="100%"
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
        >
          <span style={{ marginRight: '3px' }}>Conectar TikTok </span>
          <FaTiktok fontSize={16} />
        </Button>
      </Box>
    </Box>
  );

  const ErrorContent = (
    <Box p={5}>
      <Text mb={2} fontSize={24} fontFamily="DM Sans" fontWeight="bold">
        Tu cuenta no es elegible
      </Text>
      <Text fontFamily="DM Sans" mb={4}>
        Parece que no cumples alguno de los dos requisitos :(
      </Text>
      <Box
        fontFamily="DM Sans"
        bg="#fff"
        width="100%"
        borderStyle="dashed"
        borderColor="#000000"
        borderWidth="1px"
        borderRadius={6}
        mb={3}
        display="flex"
        alignItems="center"
        p={2}
        backgroundColor="rgba(253, 0, 0, 0.26)"
      >
        <Box>
          <Image
            boxSize="40px"
            objectFit="cover"
            src={FollowersIcon}
            alt="min followers criteria"
          />
        </Box>
        <Box ml={2}>
          <Text fontWeight="semibold" fontFamily="DM Sans">
            Tener +500 seguidores
          </Text>
        </Box>
      </Box>
      <Box
        fontFamily="DM Sans"
        bg="#fff"
        width="100%"
        borderStyle="dashed"
        borderColor="#000000"
        borderWidth="1px"
        borderRadius={6}
        mb={4}
        display="flex"
        alignItems="center"
        p={2}
        backgroundColor="rgba(253, 0, 0, 0.26)"
      >
        <Box>
          <Image boxSize="40px" objectFit="cover" src={VideosIcon} alt="min videos criteria" />
        </Box>
        <Box ml={2}>
          <Text fontWeight="semibold" fontFamily="DM Sans">
            Tener +10 vídeos
          </Text>
        </Box>
      </Box>
      <Text fontFamily="DM Sans" mb={4}>
        Si no es así, puedes ponerte en contacto con nosotros en help@cruwi.com
      </Text>
      <Text fontFamily="DM Sans" mb={4}>
        Te animamos a que lo intentes de nuevo más adelante o conectes otra cuenta.
      </Text>
      <Box marginTop="50px">
        <Button
          onClick={() => handleClickConnectTikTok()}
          width="100%"
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
        >
          Intentar de nuevo
        </Button>
        <Button
          marginTop="8px"
          fontFamily="DM Sans"
          height="auto"
          fontSize="14px"
          display="block"
          textDecoration="underline"
          fontWeight="medium"
          variant="unstyled"
          width="100%"
          onClick={() => {
            console.log();
          }}
        >
          Eliminar cuenta
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      size={isLargerThan768 ? 'lg' : 'lg'}
      isOpen={isOpenConnectTikTokModal}
      onClose={onCloseConnectTikTokModal}
      isCentered
      scrollBehavior={isLargerThan768 ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        border="2px solid #EBD0FF"
        borderRadius="24px"
        m={5}
        maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
        bg="#feffef"
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth="1px"
        >
          <Text fontSize="16px"> </Text>
          <Box cursor="pointer">
            <FiX
              size="20px"
              onClick={
                connectionError
                  ? handleClickCloseConnectTikTokModalAndClearParams
                  : onCloseConnectTikTokModal
              }
            />
          </Box>
        </ModalHeader>
        <ModalBody padding={0}>
          {connectionError === 'requirements' ? ErrorContent : InfoContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
