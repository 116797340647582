export default function isValidVideoUrl(url) {
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (e) {
    return false;
  }

  const { hostname, pathname } = parsedUrl;

  // TikTok validation
  // Accept if hostname includes 'tiktok.com'
  // TikTok patterns:
  // e.g. https://www.tiktok.com/@brizz0810/video/7439495443584650538
  // e.g. https://vm.tiktok.com/ZGdhfmwVj/
  if (hostname.includes('tiktok.com')) {
    // For a TikTok URL, just ensuring 'tiktok.com' in hostname is often enough.
    // Further validation can be done if needed (e.g., checking if path starts with /@)
    return true;
  }

  // Instagram validation
  // Instagram patterns:
  // e.g. https://www.instagram.com/reel/DCKYR-Oxm93
  // e.g. https://www.instagram.com/stories/edu.coto/3527353532580941131
  if (hostname.includes('instagram.com')) {
    // Check if pathname includes known video endpoints like reel or stories
    if (pathname.includes('/reel/') || pathname.includes('/stories/')) {
      return true;
    }
  }

  return false;
}
