import { Text, Flex, Image } from '@chakra-ui/react';

import CruwiIcon from '../../Assets/Images/logo-rayas-cruwi.svg';

export default function CruwiCreditsButtonShape({ onClick, creditsNumber = 0 }) {
  return (
    <Flex onClick={onClick} position="relative">
      <Flex
        border="1.5px solid black"
        mr="10px"
        mt="2px"
        borderRadius={10}
        cursor="pointer"
        _hover={{
          backgroundColor: '#EBD0FF',
        }}
        fontSize="12px"
        pl={2}
        w="46px"
      >
        <Text mr="4px" fontWeight="bold">
          {creditsNumber}
        </Text>
      </Flex>
      <Image
        position="absolute"
        right="9px"
        width="26px"
        height="26px"
        top="-0.5px"
        src={CruwiIcon}
        cursor="pointer"
      />
    </Flex>
  );
}
