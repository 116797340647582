import apiSlice from '../../api/apiSlice';

export const challengesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChallenges: builder.query({
      query: ({ status, isMission }) => {
        const params = new URLSearchParams();
        if (status) {
          params.append('isActive', status);
        }
        if (isMission) {
          params.append('isMission', true);
        } else {
          params.append('isMission', false);
        }
        return `challenges?${params.toString()}`;
      },
      providesTags: ['Challenges'],
    }),
    participateInChallenge: builder.mutation({
      query: (challengeId) => ({
        url: `participations`,
        method: 'POST',
        body: { challengeId },
      }),
      invalidatesTags: ['Participations', 'Challenges', 'Affiliations'],
    }),
    sendVideoLink: builder.mutation({
      query: ({ challengeId, videoData }) => ({
        url: `participations/${challengeId}/video`,
        method: 'POST',
        body: videoData,
      }),
      invalidatesTags: ['Participations', 'Challenges'],
    }),
    claim: builder.mutation({
      query: (challengeId) => ({
        url: `challenges/${challengeId}/claim`,
        method: 'POST',
      }),
      invalidatesTags: ['Participations', 'Challenges'],
    }),
    unlockBalance: builder.mutation({
      query: (balanceId) => ({
        url: `user/pending-balances/${balanceId}/unlock`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges', 'PendingBalances'],
    }),
    notifyMe: builder.mutation({
      query: (challengeId) => ({
        url: `challenges/${challengeId}/subscribe`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    claimReferral: builder.mutation({
      query: (referralId) => ({
        url: `user/referrals/${referralId}/claim`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData'],
    }),
    stopParticipatingInChallenge: builder.mutation({
      query: (challengeId) => ({
        url: `participations/${challengeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Participations', 'Challenges', 'Affiliations'],
    }),
    repeatChallenge: builder.mutation({
      query: (challengeId) => ({
        url: `participations/${challengeId}/repeat`,
        method: 'POST',
      }),
      invalidatesTags: ['Participations', 'Challenges', 'Affiliations'],
    }),
    uploadChallengeVideo: builder.mutation({
      query: ({ userId, challengeId, url }) => ({
        url: `user/${userId}/challenges/${challengeId}/upload-video`,
        method: 'POST',
        body: { url },
      }),
      invalidatesTags: ['Participations', 'UserChallenges'],
    }),
  }),
});

export const {
  useGetChallengesQuery,
  useParticipateInChallengeMutation,
  useSendVideoLinkMutation,
  useClaimMutation,
  useUnlockBalanceMutation,
  useNotifyMeMutation,
  useClaimReferralMutation,
  useUploadChallengeVideoMutation,
  useStopParticipatingInChallengeMutation,
  useRepeatChallengeMutation,
} = challengesApiSlice;
