/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { Box, Button, Text, IconButton, Flex, Input, Divider, useToast } from '@chakra-ui/react';

import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';

import { MdPlayCircleOutline, MdPauseCircleOutline, MdAdd } from 'react-icons/md';

import { useGetUserDataQuery, useUpdateMutation } from '../../Redux/features/auth/authApiSlice';

import LoadingBriefings from '../../Components/LoadingBriefings/LoadingBriefings';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import HeaderUGC from '../../Components/HeaderUGC/HeaderUGC';
import FooterNavUGC from '../../Components/FooterNavUGC/FooterNavUGC';

import DefaultProfileImage from '../../Assets/Images/default_profile_image.png';

function VideoSlider({ videos }) {
  const navigate = useNavigate();
  const [currentPlaying, setCurrentPlaying] = useState(null);

  const handlePlayPause = (index) => {
    setCurrentPlaying(currentPlaying === index ? null : index);
  };

  // Assuming a 16:9 aspect ratio for the videos and placeholders
  const aspectRatio = 9 / 16;

  // Solo mostramos 3 vídeos y si no llega, un placeholder por cada que no haya hasta 3
  const playableVideos = videos.slice(0, 3);
  const placeholdersCount = 3 - playableVideos.length;

  return (
    <Flex gap="4" p="2">
      {videos.slice(0, 3).map((video, index) => (
        <Box
          key={index}
          position="relative"
          width="100%"
          pb={`${aspectRatio * 100}%`}
          borderRadius="12px"
          overflow="hidden"
          bg="black"
        >
          <ReactPlayer
            url={video.url}
            playing={currentPlaying === index}
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: '0', left: '0', objectFit: 'cover' }}
            onClick={() => handlePlayPause(index)}
          />
          <IconButton
            aria-label={currentPlaying === index ? 'Pause' : 'Play'}
            icon={currentPlaying === index ? <MdPauseCircleOutline /> : <MdPlayCircleOutline />}
            size="sm"
            isRound
            bg="#ffffffab"
            fontSize="20px"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="10"
            onClick={() => handlePlayPause(index)}
          />
        </Box>
      ))}

      {[...Array(placeholdersCount)].map((_, index) => (
        <Box
          key={`placeholder-${index}`}
          position="relative"
          width="100%"
          pb={`${aspectRatio * 100}%`} // This maintains the aspect ratio
          borderRadius="12px"
          bg="#EBD0FF"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => navigate('/ugc/profile/edit')}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
          >
            <MdAdd size="24px" color="black" />
            <Text fontSize="12px">Add Video</Text>
          </Box>
        </Box>
      ))}
    </Flex>
  );
}

export default function UGCProfile() {
  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const navigate = useNavigate();

  // Pedimos los datos del usuario
  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery(null, {
    pollingInterval: 30000,
  });

  // Función para updatear el usuario
  const [update] = useUpdateMutation();

  // Address
  const [address, setAddress] = useState((user && user.deliveryAddress) || '');
  const handleSelectAddress = async (value, id) => {
    const allAdressData = await geocodeByPlaceId(id);
    setAddress(allAdressData[0].formatted_address);
  };

  // Imagen de perfil
  const [selectedImage, setSelectedImage] = useState((user && user.profilePicture) || null);
  const [selectedImageToSend, setSelectedImageToSend] = useState(null);

  const handleChangeLogo = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageToSend(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      // Create form data to send to the backend
      const formData = new FormData();
      formData.append('image', file);

      try {
        // Update the user profile with the new image
        await update(formData).unwrap();
        showToast('Tu imagen de perfil ha sido actualizada. Puede tardar unos minutos en actualizarse', 'success');
      } catch (error) {
        console.error('Error updating profile picture: ', error);
        showToast('Error al actualizar la imagen de perfil', 'error');
      }
    }
  };

  if (isUserLoading) {
    return <LoadingBriefings />;
  }

  // Enviamos a guardar la dirección solo por ahora
  const handleSubmit = async () => {
    console.log(selectedImageToSend);
    // guardamos la dirección
    const formData = new FormData();
    formData.append('deliveryAddress', address);
    try {
      // Update the user profile with the new image
      await update(formData).unwrap();
      showToast('Tu dirección de envío ha sido actualizada', 'success');
    } catch (error) {
      console.error('Hubo un error actualizando tu dirección: ', error);
      showToast('Error al actualizar la imagen de perfil', 'error');
    }
  };

  return (
    <Box bg="#ddded1" minH="100vh">
      <ScrollToTop />
      <HeaderUGC userData={user} />
      <Box pb={10} m={5}>
        <Box mb={8} maxWidth="700px" margin="0 auto" fontFamily="DM Sans">
          {/* Usuario, imagen y nombre */}
          <Flex alignItems="center" mb={6}>
            <Box>
              <Input type="file" onChange={handleChangeLogo} display="none" id="file-input" />
              <label htmlFor="file-input">
                <Box
                  width="64px"
                  height="64px"
                  mr={4}
                  cursor="pointer"
                  borderRadius="50%"
                  backgroundPosition="center"
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  backgroundColor="white"
                  backgroundImage={
                    selectedImage ||
                    (user.profilePicture ? user.profilePicture : DefaultProfileImage)
                  }
                />
              </label>
            </Box>
            <Flex flexDir="column">
              <Text fontSize="sm" fontWeight="bold">
                {user && user.name}
              </Text>
              <Text fontSize="sm">{user && user.ugcTikTok}</Text>
            </Flex>
          </Flex>

          {/* Editar su portafolio */}
          <Box
            fontSize="14px"
            borderRadius="24px"
            bg="linear-gradient(180deg, #FFFFF2 80.73%, #EBD0FF 100%)"
          >
            <Box p="16px 16px 8px 16px">
              <VideoSlider videos={user && user.ugcVideos} />
            </Box>
            <Button
              onClick={() => navigate('/ugc/profile/edit')}
              bg="transparent"
              width="full"
              fontSize="14px"
            >
              Ver y editar tu portafolio
            </Button>
          </Box>

          <Box height="24px" />

          {/* Dirección */}
          <Box mb="20px">
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              Tu dirección
            </Text>
            <Flex width="100%" flexDir="column">
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelectAddress}
                debounce={600}
                highlightFirstSuggestion
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <Box>
                    <Input
                      size="sm"
                      borderRadius="5px"
                      {...getInputProps({ placeholder: user && user.deliveryAddress })}
                      width="100%"
                      bg="white"
                      border="1px solid"
                    />

                    <Box>
                      {loading ? <div>...loading</div> : null}
                      <Box mt={1} maxH="200px" overflowY="auto">
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#EBD0FF' : '#f4f7f9',
                            padding: '5px',
                            cursor: 'pointer',
                            borderRadius: '4px',
                            marginBottom: '5px',
                            fontSize: '12px',
                          };

                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </PlacesAutocomplete>
              <Button
                _hover={{ bg: '#000000' }}
                paddingRight="12px"
                paddingLeft="12px"
                bg="#000000"
                color="white"
                variant="solid"
                size="sm"
                borderRadius="5px"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </Flex>
            <Text
              mt={1}
              fontStyle="italic"
              fontSize="10px"
              lineHeight={1.3}
              color="black"
              opacity={0.85}
            >
              Las marcas necesitan tu dirección completa para poder enviarte los productos de las
              colaboraciones en las que participes. Asegúrate de poner la calle, el número y el
              piso/apartamento.
            </Text>
          </Box>

          <Divider borderColor="black" opacity="0.1" />

          {/* Ayuda */}
          <Box mt={5}>
            <Text fontSize="14px" lineHeight={1.3}>
              Si necesitas cambiar algún dato de tu cuenta, contáctanos en help@cruwi.com con el
              asunto &apos;UGC cambios&apos;.
            </Text>
          </Box>
        </Box>
      </Box>
      <FooterNavUGC />
    </Box>
  );
}
