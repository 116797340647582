/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
  ModalFooter,
  useMediaQuery,
  Box,
  useDisclosure,
} from '@chakra-ui/react';

import { formatDateToDayMonth } from '../../../Utils/date';

import ChallengeItemModalHeader from './ChallengeItemModalHeader/ChallengeItemModalHeader';
import ChallengeItemModalRewards from './ChallengeItemModalRewards/ChallengeItemModalRewards';
import ChallengeItemModalRewardsNew from './ChallengeItemModalRewardsNew/ChallengeItemModalRewardsNew';
import ChallengeItemModalDescription from './ChallengeItemModalDescription/ChallengeItemModalDescription';
import ChallengeItemModalExampleVideos from './ChallengeItemModalExampleVideos/ChallengeItemModalExampleVideos';
import ChallengeItemModalSocialTags from './ChallengeItemModalSocialTags/ChallengeItemModalSocialTags';
import ChallengeItemModalRequirements from './ChallengeItemModalRequirements/ChallengeItemModalRequirements';
import ChallengeItemModalNecessaryLinks from './ChallengeItemModalNecessaryLinks/ChallengeItemModalNecessaryLinks';
import ChallengeItemModalBrandInfo from './ChallengeItemModalBrandInfo/ChallengeItemModalBrandInfo';
import ChallengeItemModalConfirmation from './ChallengeItemModalConfirmation/ChallengeItemModalConfirmation';

export default function ChallengeItemModal({
  status,
  isMission,
  userCanParticipateInMissions,
  challenge,
  userData,
  moneyToClaim,
  videoLink,
  isLoadingSendLink,
  handleChangeVideoLink,
  videoLinkError,
  isOpen,
  isComingSoon,
  isFull,
  maxVideos,
  participantsCount,
  isParticipating,
  isSubscribed,
  isClaimable,
  isClaimed,
  isDeclined,
  closeModal,
  openVideoModal,
  participate,
  sendVideoLink,
  repeat,
  claim,
  notifyMe,
}) {
  // Modal de confirmación de acciones (misiones y retos)
  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  // Cambiamos el contenido y acción del botón según el estado del challenge y las plazas
  let buttonContent = 'Participar';
  let buttonAction = onOpenConfirmationModal;
  let isDisabled = false;
  let showFooterText = false; // no ponemos texto en esta acción
  let footerText = 'Plazas del reto completas';

  // Configuración del Modal de confirmación según sea una acción u otra (2 tipos) - por defecto es participar
  let confirmationModalButtonActionText = 'Sí, participar';
  let confirmationModalAction = participate;
  let actionToConfirm = 'participate';

  // Conditional logic for button content and action
  if (status === 'active') {
    buttonContent = 'Enviar Enlace';
    buttonAction = onOpenConfirmationModal;
    confirmationModalAction = sendVideoLink;
    confirmationModalButtonActionText = 'Enviar';
    actionToConfirm = 'unparticipate'; // TODO: change
  } else if (status === 'approved' && !isClaimable) {
    buttonContent = 'En espera de recompensas...';
    buttonAction = () => console.log('Reto aprobado');
    isDisabled = true;
  } else if (isClaimable) {
    buttonContent = 'Reclamar';
    buttonAction = onOpenConfirmationModal;
    confirmationModalAction = claim;
    confirmationModalButtonActionText = 'Reclamar';
    actionToConfirm = 'claim';
  } else if (isDeclined) {
    buttonContent = 'Repetir';
    buttonAction = repeat;
  } else if (isClaimed) {
    buttonContent = 'Completado';
    buttonAction = () => console.log('Reto completado');
    isDisabled = true;
  } else if (isComingSoon) {
    buttonContent = 'Me interesa';
    buttonAction = onOpenConfirmationModal;
    confirmationModalAction = onCloseConfirmationModal;
    confirmationModalButtonActionText = 'Cerrar';
    showFooterText = true;
    footerText = (
      <span>
        Disponible el {formatDateToDayMonth(challenge?.launchDate)} - Apúntate para tener prioridad
      </span>
    );
  }

  if (isFull && !isParticipating) {
    buttonContent = 'Notifícame';
    buttonAction = onOpenConfirmationModal;
    confirmationModalAction = notifyMe;
    confirmationModalButtonActionText = 'Sí, notifícame';
    actionToConfirm = 'notifyme';
    isDisabled = false;
    showFooterText = true;
  }

  if (isSubscribed && isFull) {
    buttonContent = 'En espera';
    buttonAction = () => console.log('Ya estás suscrito al reto');
    isDisabled = true;
  }

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Modal
      size={isLargerThan768 ? 'md' : 'full'}
      onClose={closeModal}
      isOpen={isOpen}
      scrollBehavior="inside"
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent bg="#fffff2">
        <ModalBody p={0}>
          <ChallengeItemModalHeader challenge={challenge} closeModal={closeModal} />
          <ChallengeItemModalRewards
            challenge={challenge}
            participantsCount={participantsCount}
            maxVideos={maxVideos}
          />
          <ChallengeItemModalRewardsNew isMission={isMission} challenge={challenge} />
          <ChallengeItemModalDescription
            isMission={isMission}
            description={challenge.description}
            challenge={challenge}
          />
          <ChallengeItemModalExampleVideos challenge={challenge} openVideoModal={openVideoModal} />
          <ChallengeItemModalSocialTags challenge={challenge} isParticipating={isParticipating} />
          <ChallengeItemModalRequirements challenge={challenge} />
          <ChallengeItemModalNecessaryLinks challenge={challenge} />
          <ChallengeItemModalBrandInfo challenge={challenge} />
          <ChallengeItemModalConfirmation
            isMission={isMission}
            userCanParticipateInMissions={userCanParticipateInMissions}
            userData={userData}
            moneyToClaim={moneyToClaim}
            videoLink={videoLink}
            isLoadingSendLink={isLoadingSendLink}
            handleChangeVideoLink={handleChangeVideoLink}
            videoLinkError={videoLinkError}
            isOpenConfirmationModal={isOpenConfirmationModal}
            onCloseConfirmationModal={onCloseConfirmationModal}
            confirmationModalAction={confirmationModalAction}
            confirmationModalButtonActionText={confirmationModalButtonActionText}
            actionToConfirm={actionToConfirm}
            isComingSoon={isComingSoon}
          />
        </ModalBody>
        {status !== 'revision' && (
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            boxShadow="0 -3px 10px 1px #dacfcf78"
          >
            {showFooterText && (
              <Box width="100%">
                {showFooterText && (
                  <Text textAlign="center" fontFamily="DM Sans" fontSize="12px">
                    {footerText}
                  </Text>
                )}
              </Box>
            )}

            <Button
              onClick={() => buttonAction()}
              mt={2}
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
              isDisabled={isDisabled}
              fontFamily="DM Sans"
            >
              {buttonContent}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
