import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react';

import { PiCoin } from 'react-icons/pi';
import { LiaHourglassStartSolid } from 'react-icons/lia';

import BlackCheck from '../../../../Assets/Images/check-black-fill.svg';
import CloseX from '../../../../Assets/Images/close-red.png';
import CruwiIcon from '../../../../Assets/Images/logo-rayas-cruwi.svg';

import formatCentsToEur from '../../../../Utils/formatCentsToEur';

import IsComingForm from '../../../IsComingForm/IsComingForm';

export default function ChallengeItemModalConfirmation({
  isMission,
  userCanParticipateInMissions,
  userData,
  moneyToClaim,
  videoLink,
  isLoadingSendLink,
  handleChangeVideoLink,
  videoLinkError,
  confirmationModalAction,
  confirmationModalButtonActionText,
  isOpenConfirmationModal,
  onCloseConfirmationModal,
  actionToConfirm,
  isComingSoon,
}) {
  // Cambiamos el modal en función del tipo de acción a confirmar (actionToConfirm)

  // Acción de participar por defecto
  let modalContent = (
    <Box fontFamily="DM Sans">
      <Text fontWeight="bold" fontSize="20px" mb="16px">
        Participar en este reto
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        Cosas que debes tener en cuenta cuando participas en un reto:
      </Text>
      <Flex flexDir="column">
        <Flex mb="12px">
          <Box
            cursor="pointer"
            border="1px solid"
            boxShadow="1px 1px 0px 0px #000"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundColor="#FFFFF2"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box transform="rotate(270deg)">
              <PiCoin size={14} color="#000" />
            </Box>
          </Box>
          <Flex fontSize="14px">
            <p>
              <b>
                Participar cuesta un crédito (
                <img
                  style={{ display: 'inline', marginTop: '-3px', verticalAlign: 'middle' }}
                  width="20px"
                  src={CruwiIcon}
                  alt=""
                />
                ). <br />
              </b>
              Lo recuperas en cuanto subas el video. Si no lo subes, lo pierdes para siempre.
            </p>
          </Flex>
        </Flex>

        <Flex mb="12px">
          <Box
            cursor="pointer"
            border="1px solid"
            boxShadow="1px 1px 0px 0px #000"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundColor="#FFFFF2"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <LiaHourglassStartSolid size={14} color="#000" />
            </Box>
          </Box>
          <Flex fontSize="14px">
            <p>
              <b>Tienes 72 horas para subir el video </b> desde que participas. Pasado este tiempo
              tu plaza será liberada y perderás el crédito gastado.
            </p>
          </Flex>
        </Flex>

        <Flex mb="12px">
          <Box
            cursor="pointer"
            border="1px solid"
            boxShadow="1px 1px 0px 0px #000"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundRepeat="no-repeat"
            backgroundSize="12px"
            backgroundPosition="center"
            backgroundColor="#FFFFF2"
            backgroundImage={`url(${BlackCheck})`}
          />
          <Flex fontSize="14px">
            <p>
              <b>La marca no tiene que confirmar tu participación.</b> Cuando participas el
              siguiente paso es subir el video.
            </p>
          </Flex>
        </Flex>
      </Flex>
      <Button
        onClick={() => confirmationModalAction()}
        mt={2}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#EBD0FF"
        color="black"
        width="100%"
      >
        {confirmationModalButtonActionText}
      </Button>
    </Box>
  );

  if (actionToConfirm === 'participate' && isMission) {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Participar en esta misión
        </Text>
        <Text fontSize="16px" lineHeight={1.3}>
          Cosas que debes tener en cuenta cuando participas en una misión.
        </Text>
        <Flex mt="12px" flexDir="column">
          Hola
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  // si es misión cambia el texto
  if (actionToConfirm === 'unparticipate') {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Envía el enlace de tu vídeo
        </Text>
        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              Una vez enviado, la marca podrá revisarlo y aprobarlo. Además, al enviar el enlace,
              recuperarás el crédito que utilizaste para realizar este reto.
            </Text>
          </Flex>
          <Box>
            <FormControl isInvalid={videoLinkError.length > 0}>
              <FormLabel fontWeight="bold" mb={1}>
                Enlace del vídeo
              </FormLabel>
              <Input
                value={videoLink}
                onChange={handleChangeVideoLink}
                borderRadius="8px"
                size="sm"
                type="tel"
                bg="white"
              />
              {videoLinkError && (
                <FormErrorMessage>
                  <Flex mb="12px">
                    <Box
                      cursor="pointer"
                      border="1px solid"
                      boxShadow="1px 1px 0px 0px #e53e3e"
                      mr={2}
                      width="20px"
                      height="20px"
                      minW="20px"
                      borderRadius="50%"
                      backgroundRepeat="no-repeat"
                      backgroundSize="8px"
                      backgroundPosition="center"
                      backgroundColor="#FFFFF2"
                      backgroundImage={`url(${CloseX})`}
                    />
                    <Flex fontSize="14px">
                      <p>{videoLinkError}</p>
                    </Flex>
                  </Flex>
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt="60px"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          isLoading={isLoadingSendLink}
        >
          {confirmationModalButtonActionText}
        </Button>
        {isLoadingSendLink && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="rgba(254, 255, 239, 0.8)"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            zIndex="1"
            borderRadius="24px"
          >
            <Spinner size="xl" color="black" thickness="4px" />
            <Text fontSize="14px" mt={4} fontWeight="bold">
              Validando link, por favor espera…
            </Text>
            <Text fontSize="14px">¡No cierres el popup!</Text>
          </Flex>
        )}
      </Box>
    );
  }

  if (actionToConfirm === 'notifyme') {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          ¿Te notificamos?
        </Text>

        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              Cada día a las 10:00 se liberan plazas y te notificaremos por email. Tendrás que
              entrar y darle a participar. ¡Sé rápidx!
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (isComingSoon) {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Apúntate al reto
        </Text>
        <IsComingForm userData={userData} />
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (actionToConfirm === 'claim') {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Reclama tus {formatCentsToEur(moneyToClaim)}
        </Text>

        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              Has conseguido <strong>{formatCentsToEur(moneyToClaim)}</strong> completando este
              reto. El vídeo tiene que estar público un mínimo de 30 días. Por ello, la recompensa
              de este reto se incluirá en &quot;saldo pendiente&quot;. Pasados esos 30 días, podrás
              incluirlo en tu saldo disponible para retirar.
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (isMission === true && userCanParticipateInMissions === false) {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          No puedes participar :(
        </Text>

        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              No cumples los requisitos para participar en las misiones. Actualmente sólo los
              creadores con más de{' '}
              <strong>5.000 seguidores pueden participar en las misiones</strong>.
            </Text>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Modal
      isCentered
      isOpen={isOpenConfirmationModal}
      onClose={onCloseConfirmationModal}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent border="2px solid black" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          {modalContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
