import { useDisclosure } from '@chakra-ui/react';

import CruwiCreditsButtonShape from './CruwiCreditsButtonShape';

import CruwiCreditsModal from '../CruwiCreditsModal/CruwiCreditsModal';

export default function CruwiCreditsButton({ creditsNumber }) {
  // Modal de confirmación de acciones (misiones y retos)
  const {
    isOpen: isOpenCruwiCreditsModal,
    onOpen: onOpenCruwiCreditsModal,
    onClose: onCloseCruwiCreditsModal,
  } = useDisclosure();

  return (
    <>
      <CruwiCreditsButtonShape creditsNumber={creditsNumber} onClick={onOpenCruwiCreditsModal} />
      <CruwiCreditsModal
        creditsNumber={creditsNumber}
        isOpenCruwiCreditsModal={isOpenCruwiCreditsModal}
        onCloseCruwiCreditsModal={onCloseCruwiCreditsModal}
      />
    </>
  );
}
