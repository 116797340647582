/* eslint-disable no-console */

import React from 'react';

import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';

import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import useCopyToClipboard from '../../../Hooks/useCopyToClipboard';
import useShareApi from '../../../Hooks/useShareApi';

import ReferralFriendItem from './ReferralFriendItem';
import ReferralNoFriends from './ReferralNoFriends';

export default function ReferralCard({ userData }) {
  const userReferralCode = userData?.referralCode;
  const userReferrals = userData?.referrals;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [copy] = useCopyToClipboard();
  const share = useShareApi();

  const handleShareClick = () => {
    share({
      title: 'Regístrate en CRUWI',
      text: 'Colabora con tus marcas favoritas en TikTok e Instagram. Regístrate con mi link de invitación para ganar 1€ cuando completes tu primer reto. Mi link ->',
      url: `https://creators.cruwi.com/register?referralCode=${userReferralCode}`,
    });
  };

  return (
    <>
      <Box
        minWidth="300px"
        maxWidth="300px"
        bg="#fffff2"
        borderRadius="16px"
        boxShadow="2px 2px 0px 0px #000"
        border="2px solid black"
        p={3}
        mr={3}
      >
        <Box>
          <Heading fontSize="16px">Invita a un amigo y ganad 2€</Heading>
          <Text fontSize="12px" lineHeight={1.2} mt={2}>
            Ganaréis 1€ cada uno cuando tu amigx complete un reto con éxito.
          </Text>
        </Box>
        <Flex mt={3} mb={3} justifyContent="space-between">
          <Flex flexDirection="column">
            <Text fontSize="12px" fontWeight="bold">
              Recompensa
            </Text>
            <Text fontSize="12px">1€</Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontSize="12px" fontWeight="bold">
              Tiempo estimado
            </Text>
            <Text fontSize="12px">3 min</Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => onOpen()}
          height="38px"
          fontSize="14px"
          _hover={{ bg: '#FFFFF2' }}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#FFFFF2"
          color="black"
          width="100%"
          size="md"
        >
          Invitar amigxs
        </Button>
      </Box>

      <Drawer isOpen={isOpen} placement="right" size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="55px"
            borderBottomWidth="1px"
            bg="#fffff2"
          >
            <Text>Invita a tus amigxs</Text>
          </DrawerHeader>

          <DrawerBody fontFamily="DM Sans" bg="#FFFFF2" padding={0}>
            <Box p={5}>
              <Box
                p={3}
                paddingBottom={4}
                bg="#fffff2"
                borderRadius="16px"
                boxShadow="2px 2px 0px 0px #000"
                border="2px solid black"
                mb={5}
              >
                <Text textAlign="center">Mi código de referido</Text>
                <Box height="10px" />
                <Box
                  width="60%"
                  margin="0 auto"
                  borderRadius={4}
                  bg="#ffffff"
                  textAlign="center"
                  fontSize="24px"
                  fontWeight="bold"
                  onClick={() => copy(`${userReferralCode}`)}
                  border="1px solid #d9d4d4"
                >
                  {userReferralCode}
                </Box>
                <Box height="10px" />
                <Flex flexDirection="column">
                  <Button
                    onClick={() => handleShareClick()}
                    margin="0 auto 10px auto"
                    size="md"
                    height="38px"
                    width="60%"
                    fontSize="14px"
                    _hover={{ bg: '#000000' }}
                    bg="#000000"
                    color="white"
                    rightIcon={<ExternalLinkIcon />}
                  >
                    Enviar mi link
                  </Button>
                  <Button
                    onClick={() =>
                      copy(`https://creators.cruwi.com/register?referralCode=${userReferralCode}`)
                    }
                    margin="0 auto"
                    border="1px solid"
                    borderColor="#000000"
                    size="md"
                    height="38px"
                    width="60%"
                    fontSize="14px"
                    _hover={{ bg: '#FFFFFF' }}
                    bg="#FFFFFF"
                    color="black"
                    rightIcon={<CopyIcon />}
                  >
                    Copiar mi link
                  </Button>
                </Flex>
                <Box height="20px" />
                <Divider borderColor="#d9d4d4" />
                <Box height="20px" />
                <Flex>
                  <Box borderRight="1px solid #d9d4d4" textAlign="center" p={3}>
                    <Text fontSize="18px" fontWeight="bold">
                      1€
                    </Text>
                    <Text lineHeight="1" fontSize="12px">
                      para ti, por amigx que complete un reto
                    </Text>
                  </Box>
                  <Box textAlign="center" p={3}>
                    <Text fontSize="18px" fontWeight="bold">
                      1€
                    </Text>
                    <Text lineHeight="1" fontSize="12px">
                      para ellos, cuando completen un reto
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                p={3}
                paddingBottom={4}
                bg="#fffff2"
                borderRadius="16px"
                boxShadow="2px 2px 0px 0px #000"
                border="2px solid black"
              >
                <Text fontSize="20px" fontWeight="bold" mb={3}>
                  Tus amigxs
                </Text>
                {userReferrals && userReferrals.length === 0 && <ReferralNoFriends />}
                {userReferrals &&
                  userReferrals.length > 0 &&
                  userReferrals.map((referral) => (
                    <ReferralFriendItem key={referral.id} referralInfo={referral} />
                  ))}
              </Box>
            </Box>
          </DrawerBody>

          <DrawerFooter bg="#fffff2" padding={2} borderTopWidth="1px">
            <Button onClick={() => onClose()} size="sm" variant="outline" mr={3}>
              Volver al perfil
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
