/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */

import React, { useEffect, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as amplitude from '@amplitude/analytics-browser';

import { Box, Flex, Heading, Text, useToast, useDisclosure } from '@chakra-ui/react';

import { useGetChallengesQuery } from '../Redux/features/challenges/challengesApiSlice';
import { useGetUserDataQuery } from '../Redux/features/auth/authApiSlice';

import LoadingChallenges from '../Components/LoadingChallenges/LoadingChallenges';
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop';
import Header from '../Components/Header/Header';
import ChallengeItem from '../Components/ChallengeItem/ChallengeItem';
import FooterNav from '../Components/FooterNav/FooterNav';
import NoChallengeItem from '../Components/NoChallengeItem/NoChallengeItem';
import NoChallengesAvailables from '../Components/NoChallengesAvailables/NoChallengesAvailables';
import NoChallengesAvailablesToParticipate from '../Components/NoChallengesAvailablesToParticipate/NoChallengesAvailablesToParticipate';
import TikTokChallengeItem from '../Components/TikTokChallengeItem/TikTokChallengeItem';
import InstagramChallegeItem from '../Components/InstagramChallegeItem/InstagramChallegeItem';

import CreatorInfoModal from '../Components/CreatorInfoModal/CreatorInfoModal';
import NewCreditsModal from '../Components/NewCreditsModal/NewCreditsModal';
import { useGetParticipationsQuery } from '../Redux/features/participations/participationsApiSlice';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

export default function ChallengesPage() {
  // Toast
  const toast = useToast();
  const showToast = useCallback(
    (message, type) => {
      toast({
        title: `${message}`,
        status: type,
        isClosable: true,
        duration: 2000,
      });
    },
    [toast],
  );

  // Parámetros URL
  const [searchParams, setSearchParams] = useSearchParams();

  // Cuando conecta el TikTok
  const tikTokConnectedSuccess = searchParams.get('tikTokConnected');

  // Cuando conecta el facebook
  const facebookConnectedSuccess = searchParams.get('facebookConnected');

  // Cuando conecta el Spotify
  const spotifyConnectedSuccess = searchParams.get('spotifyConnected');

  // Vemos si hubo algún error (tiktok o instagram)
  const connectionError = searchParams.get('error');

  // Modal conectar TikTok
  const {
    isOpen: isOpenConnectTikTokModal,
    onOpen: onOpenConnectTikTokModal,
    onClose: onCloseConnectTikTokModal,
  } = useDisclosure();

  // Modal conectar Instagram
  const {
    isOpen: isOpenConnectFacebookModal,
    onOpen: onOpenConnectFacebookModal,
    onClose: onCloseConnectFacebookModal,
  } = useDisclosure();

  const handleClickCloseConnectTikTokModalAndClearParams = () => {
    setSearchParams({});
    onCloseConnectTikTokModal();
  };

  const handleClickCloseConnectFacebookModalAndClearParams = () => {
    setSearchParams({});
    onCloseConnectFacebookModal();
  };

  // Para filtrar retos instagram o tiktok (vemos si viene de params)
  const isInstagramInUrl = searchParams.get('platform') === 'instagram';
  const [platformFilter, setPlatformFilter] = useState(isInstagramInUrl ? 'instagram' : 'tiktok');

  // Modal créditos creadores antiguos
  const [showNewCreditsModal, setShowNewCreditsModal] = useState(false);

  // Detectamos si ha conectado el tiktok o el instagram y mostramos confetti
  useEffect(() => {
    if (
      tikTokConnectedSuccess === 'true' ||
      facebookConnectedSuccess === 'true' ||
      spotifyConnectedSuccess === 'true'
    ) {
      amplitude.track('tiktok_connected');

      const script = document.createElement('script');

      script.src = 'https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js';
      script.async = false;

      document.body.appendChild(script);

      let JSConfetti = null;

      script.onload = () => {
        if (window.JSConfetti) {
          JSConfetti = window.JSConfetti;
          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti({
            emojis: ['💸', '💰'],
            emojiSize: 100,
            confettiNumber: 30,
          });
        }
      };

      if (tikTokConnectedSuccess === 'true') {
        showToast(`TikTok conectado con éxito`, 'success');
      } else if (facebookConnectedSuccess === 'true') {
        showToast(`Instagram conectado con éxito`, 'success');
      } else if (spotifyConnectedSuccess === 'true') {
        showToast(`Spotify conectado con éxito`, 'success');
      }
    } else if (tikTokConnectedSuccess === 'false') {
      onOpenConnectTikTokModal();
      if (connectionError === 'duplicated') {
        showToast('Ya tienes este TikTok conectado a otra cuenta de CRUWI.', 'error');
      } else if (connectionError === 'missing-permissions') {
        showToast('No nos has dado los permisos necesarios para conectarte con CRUWI :( ', 'error');
      } else if (connectionError === 'requirements') {
        showToast('No cumples con los requisitos mínimos', 'error');
      } else {
        showToast('TikTok no conectado. Deberás conectarlo para participar en los retos.', 'error');
      }
    } else if (facebookConnectedSuccess === 'false') {
      onOpenConnectFacebookModal();
      if (connectionError === 'duplicated') {
        showToast('Ya tienes esta cuenta de Instagram conectado a otra cuenta de CRUWI.', 'error');
      } else if (connectionError === 'missing-permissions') {
        showToast('No nos has dado los permisos necesarios para conectarte con CRUWI :( ', 'error');
      } else if (connectionError === 'requirements') {
        showToast('No cumples con los requisitos mínimos', 'error');
      } else {
        showToast(
          'Instagram, no conectado. Deberás conectarlo para participar en los retos.',
          'error',
        );
      }
    }
  }, [
    tikTokConnectedSuccess,
    facebookConnectedSuccess,
    spotifyConnectedSuccess,
    showToast,
    connectionError,
    onOpenConnectTikTokModal,
    onOpenConnectFacebookModal,
  ]);

  // Pedimos datos de challenges activos
  const {
    data: challenges,
    isLoading,
    isSuccess,
  } = useGetChallengesQuery({
    isMission: false,
  });

  // Pedimos datos del usuario
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  const { data: participations, isLoading: isLoadingParticipations } = useGetParticipationsQuery(
    null,
    {
      pollingInterval: POLLING_INTERVAL,
    },
  );
  const participationsWithChallenge = participations?.map((participation) => {
    const participationChallenge = challenges?.data.find(
      (challenge) => challenge._id === participation.challengeId,
    );
    return { ...participation, challenge: participationChallenge };
  });

  useEffect(() => {
    if (user && user?.createdAt) {
      const userCreatedAt = new Date(user.createdAt);
      const cutoffDate = new Date('2024-12-20T00:00:00.000Z');
      if (userCreatedAt < cutoffDate) {
        const hasSeenModal = localStorage.getItem('hasSeenNewCreditsModal');
        if (!hasSeenModal) {
          setShowNewCreditsModal(true);
        }
      }
    }
  }, [user]);

  const handleCloseNewCreditsModal = () => {
    setShowNewCreditsModal(false);
    localStorage.setItem('hasSeenNewCreditsModal', 'true');
  };

  // Mostramos algo mientras carga
  if (isLoading || isUserLoading || isLoadingParticipations) {
    return <LoadingChallenges />;
  }

  console.log(participationsWithChallenge);
  // Participaciones no reclamadas, ni repetidas ni de retos no activos (no mostramos los claimed), sí mostramos las que están en revisión
  const shownParticipations = participationsWithChallenge
    .filter((participation) => participation.challenge?.isMission === false)
    .filter((participation) => {
      if (participation.status === 'revision') return true;
      if (participation.status === 'claimed') return false;
      if (participation.repeated) return false;
      return !(!participation.challenge.isActive && participation.status !== 'approved');
    })
    .filter((participation) => {
      const isInstagramChallenge = participation.challenge.isInstagram === true;
      if (platformFilter === 'instagram') {
        return isInstagramChallenge;
      }
      return !isInstagramChallenge;
    });

  // Comprobamos si tiene o no conectado el TikTok
  const hasTikTokConnected = user?.tikTokInfo.hasOwnProperty('openId');
  const hasTikTokSessionExpired = user?.tikTokInfo.outdated === true;

  // Comprobamos si tiene o no conectado el Instagram (facebook)
  const hasFacebookConnected = user?.facebookInfo?.hasOwnProperty('instagramAccountId');
  const hasFacebookSessionExpired = user?.facebookInfo.outdated === true;

  // Obtenemos de qué marca (si viene de una) viene el usuario
  const brandReferral = user?.brandReferral?.toLowerCase();

  // Comprobamos si mostramos el banner para más info (edad, género, gustos)
  const shouldShowModal =
    Object.keys(user.tikTokInfo || {}).length > 0 &&
    (!user.gender || !user.categories?.length || !user.birthDate);

  // Filtramos de los retos, los que ya está apuntado y no los mostramos 2 veces..
  const filteredChallenges = challenges?.data.filter((challenge) => {
    if (!challenge.isActive) return false;
    const participation = participationsWithChallenge.find(
      (searchedParticipation) => searchedParticipation.challengeId === challenge._id,
    );
    const isPlatformMatch =
      (platformFilter === 'tiktok' && !challenge.isInstagram) ||
      (platformFilter === 'instagram' && challenge.isInstagram);
    return (participation === undefined || participation.repeated === true) && isPlatformMatch;
  });

  const newSortedChallengesByReferredByAndPriotity = filteredChallenges.slice().sort((a, b) => {
    // First criteria: brandReferral matching with brand.name
    if (
      a.brand.name.toLowerCase() === brandReferral &&
      b.brand.name.toLowerCase() !== brandReferral
    ) {
      return -1;
    }

    if (
      a.brand.name.toLowerCase() !== brandReferral &&
      b.brand.name.toLowerCase() === brandReferral
    ) {
      return 1;
    }

    // Second criteria: higher priority
    if (a.priority !== b.priority) {
      return b.priority - a.priority; // Greater priority first
    }

    // Third criteria: newer launchDate
    const launchDateA = new Date(a.launchDate);
    const launchDateB = new Date(b.launchDate);
    return launchDateB - launchDateA; // Newer first
  });

  return (
    <Box bg="#FFFFF2" minH="100dvh">
      <ScrollToTop />
      <Header user={user} />

      <Flex maxWidth="700px" margin="0 auto" flexDir="row" borderBottom="1px solid #eee9e9">
        <Box
          cursor="pointer"
          onClick={() => setPlatformFilter('tiktok')}
          textAlign="center"
          width="50%"
        >
          <Text
            fontWeight="bold"
            mt={3}
            mb={2}
            fontSize="16px"
            opacity={platformFilter === 'tiktok' ? '1' : '0.5'}
            fontFamily="DM Sans"
          >
            Retos TikTok
          </Text>
          {platformFilter === 'tiktok' && (
            <Box
              margin="0 auto"
              bg="#EBD0FF"
              width="130px"
              height="5px"
              borderTopRightRadius="5px"
              borderTopLeftRadius="5px"
            />
          )}
        </Box>
        <Box
          cursor="pointer"
          onClick={() => setPlatformFilter('instagram')}
          textAlign="center"
          width="50%"
        >
          <Text
            fontWeight="bold"
            mt={3}
            mb={2}
            fontSize="16px"
            opacity={platformFilter === 'instagram' ? '1' : '0.5'}
            fontFamily="DM Sans"
          >
            Retos Instagram
          </Text>
          {platformFilter === 'instagram' && (
            <Box
              margin="0 auto"
              bg="#EBD0FF"
              width="130px"
              height="5px"
              borderTopRightRadius="5px"
              borderTopLeftRadius="5px"
            />
          )}
        </Box>
      </Flex>

      <Box pb="60px" mt={5} ml={5} mr={5}>
        {/* Mis retos */}
        <Box mb={8} maxWidth="700px" margin="0 auto">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading fontSize="24px" fontFamily="DM Sans">
              Mis retos
            </Heading>
          </Flex>
          <Text fontFamily="DM Sans" opacity="0.8" lineHeight={1.1} fontSize="12px">
            Retos en los que participas
          </Text>

          {/* Listado de retos en los que participas */}
          <Box mt={5}>
            <Box mb={5}>
              {(!hasTikTokConnected || hasTikTokSessionExpired) && platformFilter === 'tiktok' && (
                <TikTokChallengeItem
                  connectionError={connectionError}
                  isOpenConnectTikTokModal={isOpenConnectTikTokModal}
                  onOpenConnectTikTokModal={onOpenConnectTikTokModal}
                  onCloseConnectTikTokModal={onCloseConnectTikTokModal}
                  handleClickCloseConnectTikTokModalAndClearParams={
                    handleClickCloseConnectTikTokModalAndClearParams
                  }
                  hasExpiredSession={hasTikTokSessionExpired}
                />
              )}

              {(!hasFacebookConnected || hasFacebookSessionExpired) &&
                platformFilter === 'instagram' && (
                  <InstagramChallegeItem
                    connectionError={connectionError}
                    isOpenConnectFacebookModal={isOpenConnectFacebookModal}
                    onOpenConnectFacebookModal={onOpenConnectFacebookModal}
                    onCloseConnectFacebookModal={onCloseConnectFacebookModal}
                    handleClickCloseConnectFacebookModalAndClearParams={
                      handleClickCloseConnectFacebookModalAndClearParams
                    }
                    hasExpiredSession={hasFacebookSessionExpired}
                  />
                )}
            </Box>

            {isUserSuccess &&
              shownParticipations.map((challenge) => (
                <ChallengeItem
                  status={challenge?.status}
                  challengeData={challenge.challenge}
                  key={challenge.id}
                  userData={user}
                  videoCreateTime={new Date(challenge?.mediaPost?.createdAt)}
                  participations={participations}
                />
              ))}

            {isUserSuccess &&
              (hasTikTokConnected || hasFacebookConnected) &&
              (participations.length === 0 || shownParticipations.length === 0) && (
                <NoChallengeItem />
              )}
          </Box>
        </Box>

        {/* Retos disponibles */}
        <Box maxWidth="700px" margin="0 auto">
          <Flex justifyContent="space-between">
            <Heading fontSize="24px" fontFamily="DM Sans">
              Retos disponibles
            </Heading>
          </Flex>
          <Text fontFamily="DM Sans" opacity="0.8" lineHeight={1.1} fontSize="12px">
            Últimos retos propuestos por las marcas
          </Text>

          {/* Listado de retos disponibles */}
          <Box mt={5}>
            {isSuccess &&
              newSortedChallengesByReferredByAndPriotity?.map((challenge) => (
                <ChallengeItem
                  challengeData={challenge}
                  key={challenge._id}
                  userData={user}
                  participations={participations}
                />
              ))}

            {isSuccess && newSortedChallengesByReferredByAndPriotity.length === 0 && (
              <NoChallengesAvailablesToParticipate platformFilter={platformFilter} />
            )}

            {isSuccess && challenges?.data?.length === 0 && <NoChallengesAvailables />}
          </Box>
        </Box>
      </Box>

      <CreatorInfoModal isOpen={shouldShowModal} />
      <NewCreditsModal isOpen={showNewCreditsModal} onClose={handleCloseNewCreditsModal} />
      <FooterNav />
    </Box>
  );
}
