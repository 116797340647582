import apiSlice from '../../api/apiSlice';

export const participationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParticipations: builder.query({
      query: () => ({
        url: `participations`,
      }),
      providesTags: ['Participations'],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetParticipationsQuery } = participationsApiSlice;
