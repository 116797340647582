import {
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

import CruwiIcon from '../../Assets/Images/logo-rayas-cruwi.svg';

export default function NewCreditsModal({ isOpen, onClose }) {
  const modalContent = (
    <Box fontFamily="DM Sans">
      <Text fontWeight="bold" fontSize="20px" mb="16px">
        Nuevo sistema de créditos
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        <b>Introducimos el sistema de créditos</b>{' '}
        <img
          style={{ display: 'inline', marginTop: '-3px', verticalAlign: 'middle' }}
          width="20px"
          src={CruwiIcon}
          alt=""
        />
        <br />
        Un sistema que nos asegura que tengas la oportunidad de participar en los retos que te
        interesan.
      </Text>
      <Text fontWeight="bold" fontSize="14px" lineHeight={1.3} mb="16px">
        <u>¿Cómo funciona?</u>
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        <b>Emplea un crédito para participar en un reto. Y recupéralo subiendo el vídeo </b> para
        seguir participando en otros. Así garantizamos que las marcas vean tu mejor trabajo y tú
        tengas más chances de brillar.
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        <b>Empezarás con 2 créditos </b> (+1 de regalo si has hecho un reto en 2024). Si participas
        y subes el vídeo, no los perderás nunca. Incluso ganarás nuevos, más adelante te contaremos
        cómo.
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        Tienes más información sobre los créditos y su uso en las preguntas y respuestas haciendo
        clic en el icono de (?) del menú superior.
      </Text>

      <Box>
        <Button
          onClick={onClose}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          Entendido
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent border="2px solid black" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          {modalContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
